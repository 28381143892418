<template>
  <div class="uk-width-1-1 aboutus">
    <BigTitle title="关于新翼梦" subtitle="ABOUT&nbsp;US" />
    <div class="aboutus-content">
      <div>
        <div data-src="/img/aboutus.jpg" class="uk-width-1-1 uk-height-1-1 uk-background-cover uk-light hd-cover" uk-img></div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350.98 142.96" class="svg1">
          <line class="path4" id="path4" x1="336.29" y1="16.3" x2="211.52" y2="141.96" />
          <circle class="path3" cx="341.87" cy="9.11" r="9.11" />
          <line class="path2" x1="17.23" id="path2" y1="107.69" x2="332.76" y2="9.11" />
          <circle class="path1" cx="9.11" cy="111.81" r="9.11" />
        </svg>
        <span class="aboutus-content_1"> 麒麟软件和统信软件是我们重要的合作伙伴，秉持创新为动力，携手共进，为更多企业客户提供更好的服务。 </span>
        <span class="aboutus-content_2"
          >麒麟软件和统信软件是我们重要的合作伙伴，秉持创新为动力，携手共进，为更多企业客户提供更好的服务。麒麟软件和统信软件是我们重要的合作伙伴，秉持创新为动力，携手共进，为更多企业客户提供更好的服务。</span
        >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350.98 142.96" class="svg2">
          <line class="path6" x1="125.4" y1="125.69" x2="1" y2="1" />
          <circle class="path5" cx="132.02" cy="131.96" r="9.11" />
        </svg>
        <span class="aboutus-content_3">麒麟软件和统信软件是我们重要的合作伙伴，秉持创新为动力，携手共进，为更多企业客户提供更好的服务。 </span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';

const line1Wdith = ref('0');
onMounted(() => {
  line1Wdith.value = document.getElementById('path2').getTotalLength() + 'px';
});
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/animation.scss';

.aboutus {
  margin-top: 20px;
  .aboutus-content {
    margin-top: 20px;
    position: relative;
    overflow: hidden;
    > div {
      position: relative;
      height: calc((4700 / 3.5) * 0.65 / 1920 * 100vw);
    }
    .hd-cover {
      // margin-top: calc(-120px);
      position: absolute;
    }
    span {
      color: $color--plain;
      opacity: 0.9;
      font-size: 1rem;
    }
    .svg1 {
      position: absolute;
      // width: 250px;
      // top: 66px;
      // left: 545px;
      width: 13.1%;
      top: 8.5%;
      left: 28.5%;
    }
    .svg2 {
      position: absolute;
      // width: 250px;
      // top: 557px;
      // left: 1262px;
      width: 13.1%;
      top: 73%;
      left: 66.2%;
    }
    .path1,
    .path3,
    .path5 {
      fill: #fff;
    }

    .path4 {
      stroke-linecap: round;
    }

    .path2,
    .path4,
    .path6 {
      fill: none;
      stroke: #fff;
      stroke-miterlimit: 10;
      stroke-width: 2px;
      opacity: 0.6;
    }

    #path2 {
      stroke-dasharray: v-bind('line1Wdith'), v-bind('line1Wdith');
      stroke-dashoffset: 0;
    }

    .aboutus-content_1 {
      position: absolute;
      display: inline-block;
      font-size: 1rem;
      width: 20.8%;
      // top: 132px;
      // left: 290px;
      top: 15.2%;
      // left: 15.2%;
      right: 71.8%;
    }
    .aboutus-content_2 {
      position: absolute;
      display: inline-block;
      font-size: 1rem;
      width: 48%;
      // top: 59px;
      // left: 805px;
      top: 6.7%;
      left: 42.4%;
    }
    .aboutus-content_3 {
      position: absolute;
      display: inline-block;
      font-size: 1rem;
      // width: 325px;
      // top: 638px;
      // left: 1372px;
      width: 24.8%;
      top: 81.2%;
      left: 72.5%;
    }
  }
}
</style>
