<template>
  <div class="uk-width-1-1 nav-contact-warpper">
    <div class="nav-contact view-center">
      <div>
        <div class="uk-height-1-1 nav-contact__item">
          <span uk-icon="icon: receiver"></span>
          <span>服务热线：0755-12345678</span>
        </div>
        <div class="uk-height-1-1 nav-contact__item">
          <span uk-icon="icon: mail"></span>
          <span>联系邮箱：075512345678@domain.com</span>
        </div>
      </div>
      <button class="uk-button uk-button-primary">联系我们</button>
    </div>
  </div>
</template>
<style lang="scss">
@import '@/styles/variables.scss';

.nav-contact-warpper {
  background-color: $bg--nav-contact;
  display: flex;
  justify-content: center;
}
.nav-contact {
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: center;
  // justify-content: space-between;
  > div {
    display: flex;
    justify-content: center;
    margin-right: 15px;
  }
  .nav-contact__item {
    display: flex;
    align-items: center;
    margin-right: 15px;
    white-space: nowrap;
    span {
      color: $color--plain;
      display: inline-block;
      padding: 0 3px;
      &.uk-icon {
        color: $uk--primary;
      }
    }
  }
}
/* 
@media only screen and (max-width: 767px) {
  .nav-contact-warpper {
    display: none;
  }
}
 */
</style>
