<template>
  <div class="uk-width-1-1 product">
    <BigTitle title="经营范围" subtitle="PRODUCTS"></BigTitle>
    <div uk-grid class="product-content">
      <div class="uk-width-1-2">
        <div class="uk-child-width-1-1 product-left">
          <h3>网络服务</h3>
          <p>
            &emsp;&emsp;网络是所有技术和技能的基础，在互联网应用井喷式增长的今天，对网络的依赖仍然会持续未来的几年，网络技术服务依旧会对现有企业增长和新兴产业产生最最基础的保障。我们关注企业核心网络的瓶颈问题，和数据交换性能、架构的稳定，从而使企业核心数据得到应有的保障，达到安全稳定的运行目标。
          </p>
          <div class="subtitle" data-src="/img/products-subtitle.png" uk-img><h3>IP地址规划</h3></div>
          <ul>
            <li>VLAN及其策略规划</li>
            <li>
              IP网络设计与实施服务：为满足企业客户建设不同层次IP网络的需求，提供了数据中心、园区网、分支机构等不同场景的网络设计。实施服务通过定制化的方案、严谨的实验室验证和专家团队的审核，保障客户业务的平滑迁移。
            </li>
            <li>IP网络优化：提升企业客户IP网络的可用性，实现网络运维的规范化和智能化，帮助企业提升网络运维能力并提高客户满意度。</li>
            <li>
              光网络设计与实施服务：根据客户需求，提供详细的网络结构设计、业务设计和维护设计，实现光网络高效运营。创普拥有丰富的光网络交付经验，满足网络快速部署和业务平滑迁移需求。
            </li>
          </ul>
        </div>
      </div>
      <div class="uk-width-1-2 product-right">
        <div data-src="/img/products.png" class="uk-background-cover uk-light hd-cover" uk-img></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@/styles/variables.scss';

$size--padding-between: 50px;
$size--padding-center: 10px;
.product {
  background-color: $color--secondary;
  padding-bottom: 40px;
  :deep(.big-title) {
    h3 {
      padding-left: 10px;
      letter-spacing: 10px;
    }
  }
}

.product-content {
  //   display: flex;
  //   justify-content: center;

  > div {
    width: 50%;
  }

  h3 {
    font-size: 2rem;
    font-weight: 700;
  }

  .subtitle {
    // background-size: contain;
    background-size: cover;
    background-position-x: right;
    background-repeat: no-repeat;
    border-radius: 8px;
    width: calc(451px * 2 / 3.4);
    height: calc(82px * 2 / 2.6);
    padding-left: calc(400px * 2 / 24);
    margin-left: 10px;
    display: flex;
    align-items: center;
    h3 {
      color: $color--blue-subtitle;
      margin: 0;
    }
  }

  .product-left {
    padding-left: $size--padding-between;
    padding-right: $size--padding-center;
    ul {
      color: $color--blue-secondary;
    }
    p, ul {
      font-size: 1.3rem;
    }
  }

  .product-right {
    padding-right: $size--padding-between;
    padding-left: $size--padding-center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hd-cover {
    width: calc(710px * 2 / 3);
    height: calc(521px * 2 / 3);
  }
}
/* 
@media only screen and (max-width: 1439px) and (min-width: 1280px) {
  .product-content .hd-cover {
    width: calc(710px * 2 / 2.8);
    height: calc(521px * 2 / 2.8);
  }
}

@media only screen and (max-width: 1279px) and (min-width: 960px) {
  .product-content .hd-cover {
    width: calc(710px * 2 / 3.5);
    height: calc(521px * 2 / 3.5);
  }
}

@media only screen and (max-width: 959px) {
  .product-content {
    flex-wrap: wrap;
    > div {
      width: 100%;
      padding: 10px;
    }
    .product-right {
        padding: 0;
    }
  }
}
 */
</style>
