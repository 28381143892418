<template>
  <div class="uk-width-1-1 navbar-warpper">
    <div>
      <!-- <ClientOnly> -->
        <nav v-if="isMobile" class="uk-navbar uk-navbar-container uk-margin">
          <div class="uk-navbar-left">
            <div class="uk-navbar-toggle" uk-toggle="target: #navbarFixed">
              <span uk-navbar-toggle-icon></span>
              <span class="uk-margin-small-left">Menu</span>
            </div>
          </div>
          <div id="navbarFixed" uk-offcanvas="overlay: true">
            <div class="uk-offcanvas-bar uk-ligth">
              <button title="close" class="uk-offcanvas-close" type="button" uk-close></button>
              <div class="uk-height-1-1 logo-warpper">
                <img :data-src="'/img/logo.png'" width="105" height="63" alt="" uk-img />
              </div>
              <div class="uk-height-1-1 nav-body">
                <div>
                  <ul uk-tab class="uk-nav uk-nav-default nav-tabs">
                    <li class="uk-active"><a href="#">首页</a></li>
                    <li><a href="#">关于我们</a></li>
                    <li><a href="#">网络服务</a></li>
                    <li><a href="#">服务器运维</a></li>
                    <li><a href="#">网络安全</a></li>
                    <li><a href="#">软硬件代理</a></li>
                    <li><a href="#">网站开发</a></li>
                    <li><a href="#">UI设计</a></li>
                    <!-- <li class="uk-disabled"><a>Disabled</a></li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <nav v-else class="navbar view-center">
          <div class="uk-height-1-1 logo-warpper">
            <img :data-src="'/img/logo.png'" width="105" height="63" alt="" uk-img />
          </div>
          <div class="uk-height-1-1 nav-body">
            <div>
              <ul uk-tab class="uk-nav uk-nav-default nav-tabs">
                <template v-for="item in navList">
                  <li :class="{ 'uk-active': item.active }">
                    <a href="#" class="uk-link-reset" @click="goAnchor(item.href)">{{ item.name }}</a>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </nav>
      <!-- </ClientOnly> -->
    </div>
  </div>
</template>
<script setup>
// const isMobile = useIsMobile();
const isMobile = useState('isMobiles', () => false);

const navList = ref([
  {
    href: '#home',
    name: '首页',
    active: true,
  },
  {
    href: '#aboutus',
    name: '关于我们',
    active: false,
  },
  {
    href: '#product',
    name: '网络服务',
    active: false,
  },
  {
    href: '#devops',
    name: '服务器运维',
    active: false,
  },
  {
    href: '#security',
    name: '网络安全',
    active: false,
  },
  {
    href: '#agent',
    name: '软硬件代理',
    active: false,
  },
  {
    href: '#develop',
    name: '网站开发',
    active: false,
  },
  {
    href: '#design',
    name: 'UI设计',
    active: false,
  },
]);
onMounted(() => {
  const activeItem = navList.value.find((v) => v.active) || navList.value[0];
  if (window.location.hash) {
    navList.value.forEach((item) => {
      if (item.href === window.location.hash) {
        activeItem.active = false;
        item.active = true;
      }
    });
  } else {
    window.location.hash = activeItem.href;
  }
});
function goAnchor(id) {
  window.location.hash = id;
  setTimeout(() => {
    UIkit.scroll().scrollTo(id);
  }, 100);
}
</script>
<style lang="scss">
@import '@/styles/variables.scss';

.navbar-warpper {
  background-color: $bg--navbar;
  box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}

.uk-navbar-container {
  margin: 0;
  &:not(.uk-navbar-transparent) {
    background: inherit;
  }
  .uk-navbar-toggle {
    min-height: 50px;
    color: $color--text-plain;
  }
}

.navbar {
  height: 100px;
  line-height: 100px;
  display: flex;
  justify-content: center;
  .nav-body {
    display: flex;
    align-items: flex-end;
    margin-left: 25px;
  }
  .nav-tabs {
    position: relative;
    line-height: 75px;
    margin-bottom: 0;
    flex-wrap: nowrap;
    &::before {
      border-bottom: 4px solid transparent;
    }
    > * > a {
      border-bottom-width: 4px;
    }
  }
}
/* 
.mobile {
  .navbar-warpper {
    justify-content: flex-start;
    padding: 0 20px;
  }
}
.uk-offcanvas-bar {
  .logo-warpper,
  .nav-body {
    height: auto;
  }
  .logo-warpper {
    text-align: center;
    margin-bottom: 10px;
  }
  .nav-tabs {
    flex-direction: column;

    li,
    li a {
      color: $color--text-default !important;
    }
  }
}
 */
</style>
