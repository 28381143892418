<template>
  <div class="uk-width-1-1 footer" uk-grid>
    <div class="uk-width-1-1 footer-banner">
      <div class="uk-width-1-2 uk-background-cover uk-light hd-cover" data-src="/img/technology.png" uk-img>
        <div>
          <div>
            <span>TECHNOLOGY</span>
            <span uk-icon="icon: triangle-down; ratio: 2.2"></span>
          </div>
        </div>
      </div>
      <div class="uk-width-1-2 uk-background-cover uk-light hd-cover" data-src="/img/network.png" uk-img>
        <div>
          <div>
            <span>NETWORK</span>
            <span uk-icon="icon: triangle-down; ratio: 2.2"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-content" uk-grid>
      <div>
        <div class="uk-height-1-1 nav-contact__item">
          <span uk-icon="icon: receiver"></span>
          <span>服务热线：0755-12345678</span>
        </div>
        <div class="uk-height-1-1 nav-contact__item">
          <span uk-icon="icon: mail"></span>
          <span>联系邮箱：075512345678@domain.com</span>
        </div>
        <div class="uk-height-1-1 nav-contact__item">
          <span uk-icon="icon: location"></span>
          <span>联系地址：四川省凉山彝族自治州雷波县锦城镇水巷街78号</span>
        </div>
      </div>
      <div class="qrcode">
        <img class="hd-cover" width="150" height="150" data-src="/img/QRCode.png" alt="" uk-img />
        <span>【扫一扫，关注我们】</span>
      </div>
    </div>
    <div class="footer-copyright">
      <span>Copyright © 2023 新翼梦</span>
      <a target="_blank" class="uk-link-text" rel="noopener noreferrer" href="https://beian.miit.gov.cn/">蜀ICP备2023003089号</a>
    </div>
  </div>
</template>
<script setup></script>
<style lang="scss">
@import '@/styles/variables.scss';

.footer {
  margin: 0;
  flex-direction: column;
  background-color: $bg--nav-contact;
  .footer-banner {
    height: 271px;
    display: flex;
    padding: 0;
    .hd-cover {
      padding: 20px;
      > div {
        width: 100%;
        height: 100%;
        border-radius: 3px;
        border: 2px solid rgba(255, 255, 255, 0.6);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > div {
          display: inline-block;
        }
        span {
          color: #ffffff;
          display: block;
          text-align: center;
          font-size: 1.7rem;
          &[uk-icon] {
            margin-top: -10px;
          }
        }
      }
    }
  }
  .footer-content {
    color: rgba(255, 255, 255, 0.8);
    justify-content: center;
    align-items: flex-start;
    font-size: 1.1rem;
    margin-top: 40px;
    > div {
      padding: 0;
      margin: 0 20px;
      &.qrcode {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          margin-top: 10px;
        }
      }
      > div {
        margin: 20px 0;
      }
    }
    .nav-contact__item {
      display: flex;
      align-items: center;
      margin-right: 15px;
      white-space: nowrap;
      span {
        color: $color--plain;
        display: inline-block;
        padding: 0 3px;
        &.uk-icon {
          color: $uk--primary;
        }
      }
    }
  }
  .footer-copyright {
    color: $color--text-placeholder;
    margin: 20px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    a:not(:last-child)::after,
    span:not(:last-child)::after {
      content: ' · ';
      display: inline-block;
      white-space: pre;
    }
  }
}
</style>
