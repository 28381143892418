<template>
  <a name="home" id="home"></a>
  <HomeNavContact />
  <HomeNavbar />
  <a name="aboutus" id="aboutus"></a>
  <HomeAbout />
  <a name="product" id="product"></a>
  <HomeProduct />
  <a name="devops" id="devops"></a>
  <HomeDevOps />
  <a name="security" id="security"></a>
  <a name="agent" id="agent"></a>
  <a name="develop" id="develop"></a>
  <a name="design" id="design"></a>
  <HomeBusiness />
  <a name="cooperation" id="cooperation"></a>
  <HomeCooperation />
  <MainFooter />
  <!-- <div v-if="Array.isArray(list)" v-for="item in list">
    <span :key="item.id">{{ t('title') + t('content') + item.id }}</span>
  </div> -->
  <!-- <a>{{ currentLocale.name }}</a>
  <div v-for="item in locales" :key="item.code || item">
    <a v-if="item.current">{{ item.name || item }}</a>
    <NuxtLink v-else :to="setLocale(item.code)">
      {{ item.name || item }}
    </NuxtLink>
  </div> -->
  <button class="uk-button uk-button-default totop" :class="{ 'totop-show': showToTop }" title="totop"><a href="#" uk-totop uk-scroll></a></button>
</template>
<script setup>
defineComponent({ inheritAttrs: false });
const showToTop = useState('showToTop', () => false);
onMounted(() => {
  window.onscroll = function () {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const bodyHeight = window.screen.availHeight || 500;
    showToTop.value = scrollTop > (bodyHeight/2);
  };
});
const router = useRouter();
// const { home } = useApi();
// const messages = await home.getLangMessage();
const messages = {
  data: {},
};
const { t, locale, locales } = useI18n({
  messages: {
    en: messages.data,
    zh: messages.data,
  },
});
const setLocale = useSwitchLocalePath();

const currentLocale = computed(() => {
  let current = {};
  locales.value.some((i) => {
    if (i.code === locale.value) {
      i.current = true;
      current = i;
      return true;
    }
    return false;
  });
  return current;
});

const list = useState('list', () => '暂无数据');
const getPostList = async () => {
  try {
    const { data } = await home.getPostList();
    list.value = data.value?.list;
  } catch (err) {
    console.error('error', err);
  }
};

// getPostList();

// 启用会影响i18n的路由生成
// definePageMeta({
//   name: 'Index',
// });

const title = ref('首页 - 新翼梦');
useHead({
  title,
  meta: [
    { hid: 'keywords', name: 'keywords', content: '外贸, index' },
    { hid: 'lang', name: 'langage', content: locale },
  ],
});
</script>

<style lang="scss">
:root {
  font-size: 1em;
}

.totop {
  display: none;
  position: fixed;
  bottom: 50px;
  right: 50px;
  padding: 0;
  width: 40px;
  height: 40px;
  a {
    width: 100%;
    height: 100%;
    padding: 0;
    position: relative;
    display: flex;
    justify-content: center;
  }
}
.totop-show {
  display: block;
}
</style>
