<template>
  <div class="uk-width-1-1 business" uk-grid>
    <div uk-grid class="uk-width-1-1 business-content">
      <div class="uk-width-1-3 uk-background-cover uk-light hd-cover" data-src="/img/products2.png" uk-img></div>
      <div class="uk-width-1-3">
        <h5 class="subtitle">网络安全</h5>
        <span>等保测评&emsp;漏洞扫描&emsp;基线检查&emsp;安全运维&emsp;资产梳理</span>
      </div>
      <div class="uk-width-1-3">
        <h5 class="subtitle">软硬件代理</h5>
        <span>和华为 深信服、奇安信、戴尔、多家知名企业合作<br />信创产业链：<br />&emsp;国内信创合作</span>
      </div>
    </div>
    <div uk-grid class="uk-width-1-1 business-content">
      <div class="uk-width-1-3">
        <h5 class="subtitle">网站开发</h5>
        <span>为各类旧网站提供网站改造方案、为政府、企事业单位提供信创业务系统改造、提供定制化系统开发、OA开发</span>
      </div>
      <div class="uk-width-1-3 uk-background-cover uk-light hd-cover" data-src="/img/products3.png" uk-img></div>
      <div class="uk-width-1-3">
        <h5 class="subtitle">UI设计</h5>
        <span>LOGO设计、海报设计、折页设计、名片设计、网页设计、淘宝店铺设计、宣传单设计</span>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.business {
  font-size: 1em;
  justify-content: center;
  margin: 0;
  padding-top: calc(50 / 1920 * 100vw);
  .business-content {
    justify-content: center;
    margin: 0;
    padding: 0;
    flex-wrap: nowrap;
    > div {
      margin: 20px;
      width: calc(460px * 0.9);
      height: calc(325px * 0.9);
    //   width: calc((460 / 1) * 0.65 / 1920 * 100vw);
    //   height: calc((325 / 1) * 0.65 / 1920 * 100vw);
      &:not(.hd-cover) {
        border: 2px solid $color--border-placeholder;
        border-radius: 3px;
        box-shadow: 0px 0 15px 0px rgba(0, 0, 0, 0.2);
        padding: calc(50 / 1920 * 100vw);
      }
      h5 {
        color: $color--green-subtitle;
        font-size: 1.5rem;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $color--border-secondary;
      }
      span {
        color: $color--green-secondary;
        font-size: 1.1rem;
      }
    }
  }
}
</style>
