<template>
  <div class="uk-width-1-1 cooperation">
    <BigTitle title="合作伙伴" subtitle="COOPERATION" />
    <div class="aboutus-cooperation">
      <div class="desc">麒麟软件和统信软件是我们重要的合作伙伴，秉持创新为动力，携手共进，为更多企业客户提供更好的服务。</div>
      <div uk-grid class="unit">
        <div class="unit1">
          <div>
            <img class="hd-cover" width="640" height="359" data-src="/img/cooperation1.png" alt="" uk-img />
          </div>
        </div>
        <div class="unit2">
          <div>
            <img class="hd-cover" width="271" height="364" data-src="/img/cooperation2.png" alt="" uk-img />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.cooperation {
  background-color: $color--secondary;
  padding-bottom: 40px;
  margin: 0;
  :deep(.big-title) {
    h3 {
      padding-left: 10px;
      letter-spacing: 10px;
    }
  }
  :deep(.big-subtitle) {
    span {
      letter-spacing: 3px;
    }
  }
  .aboutus-cooperation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .desc {
      margin-top: 10px;
      width: 600px;
      font-size: 1.3rem;
      text-align: center;
    }
    .unit {
      justify-content: center;
      > div {
        border-radius: 3px;
        margin: 15px;
        padding: 20px;
        > div {
          border-radius: 3px;
          width: 478px;
          height: 251px;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          [uk-img] {
            width: 90%;
            height: 100%;
            object-fit: contain;
            // flex: 1;
            background-size: contain;
          }
        }
      }
      .unit1 {
        background-color: #041a3f;
        > div {
          border: 2px solid #ffffff;
        }
      }
      .unit2 {
        background-color: #ffffff;
        > div {
          border: 2px solid #041a3f;
        }
      }
    }
  }
}
</style>
