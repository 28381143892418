<template>
  <div class="big-title">
    <hr />
    <h3>{{ props.title }}</h3>
    <hr />
  </div>
  <div class="big-subtitle"><span>{{ props.subtitle }}</span></div>
</template>
<script setup>
defineComponent({ inheritAttrs: false });

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    default: '',
  },
});
</script>
<style lang="scss">
@import '@/styles/variables.scss';

.big-title,
.big-subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20vw;
  hr {
    display: inline-block;
    height: 1px;
    flex: 1;
    max-width: 100px;
    margin: 24px 0;
    border-top: 1px $color--text-placeholder solid;
  }
  h3 {
    font-size: 1.7rem;
    margin: 10px;
    display: inline-block;
    white-space: nowrap;
  }
  span {
    margin-top: -10px;
    padding-left: 6px;
    letter-spacing: 7px;
    white-space: nowrap;
  }
}
</style>
